@use "../../../../styles/mixins";
@use "../../../../styles/variables.module" as *;

.container {
  overflow: hidden;
  position: relative;
}

.sliderContainer {
  position: relative;
  height: 400px;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  transition: all .5s ease;
  display: flex;
  align-items: center;

  &ShowGradientRight {
    -webkit-mask-image: linear-gradient(90deg,#000 85%,transparent);
    mask-image: linear-gradient(90deg,#000 85%,transparent);
  }
  &ShowGradientLeft {
    -webkit-mask-image: linear-gradient(270deg,#000 85%,transparent);
    mask-image: linear-gradient(270deg,#000 85%,transparent);
  }
  &ShowGradientBoth {
    -webkit-mask-image: linear-gradient(90deg, transparent, #000 15%, #000 85%, transparent);
    mask-image: linear-gradient(90deg, transparent, #000 15%, #000 85%, transparent);
  }
}
.slideContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .5s ease;

  .innerContainer {
    width: 100%;
    height: 100%;
    position: relative;

    &Hidden {
      opacity: .5;
    }

    &DisallowEvents {
      iframe, video, img {
        pointer-events: none;
      }
    }
  }
}

.childSlideContainer {
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 100%;
}

.scrollArrow {
  width: 50px !important;
  height: 50px;
  text-align: center !important;
  line-height: 50px !important;
  font-size: 3rem !important;
  cursor: pointer !important;
  position: absolute;
  color: #999 !important;
  font-weight: 600 !important;

  @include mixins.screenXs {
    display: none;
  }

  &Disabled {
    pointer-events: none;
    cursor: default;
    opacity: .5;

    &:hover {
      color: #999 !important;
    }
  }

  &:hover {
    color: #333 !important;
  }

  &Next {
    right: 2%;

    @include mixins.screenXs{
      right: 0;
    }
  }
  &Prev {
    left: 2%;

    @include mixins.screenXs{
      left: 0;
    }
  }
}

.slideContainerContainer {
  height: inherit;
  width: inherit;
  position: absolute;
  transition: transform .5s ease;
}

.track {
  position: absolute;
  transition: transform .5s ease;
  bottom: 0;
}

.trackContainer {
  width: 80%;
  overflow: hidden;
  height: 400px;
  position: relative;
  margin: 0 auto;
  display: block;

  @include mixins.screenXs{
    width: 100%;
  }
}
